import { FC } from 'react'
import { Skeleton, SkeletonProps } from '@chakra-ui/react'

export interface CbGameCardSkeletonProps extends SkeletonProps {}

export const CbGameCardSkeletonOld: FC<CbGameCardSkeletonProps> = ({
  ...props
}) => {
  return (
    <Skeleton
      borderRadius={24}
      boxShadow="0px 4px 21px 3px rgba(0, 0, 0, 0.09)"
      height={{ base: '500px', lg: '340px' }}
      {...props}
    />
  )
}
