import { FC } from 'react'
import { Box, Center, CenterProps, Text } from '@chakra-ui/react'

export interface CbEmptySearchStateProps extends CenterProps {
  alert?: string
  suggestion?: string
}

export const CbEmptySearchState: FC<CbEmptySearchStateProps> = ({
  alert,
  suggestion,
  ...props
}) => {
  return (
    <Center
      backgroundColor="cloud.300"
      borderRadius={16}
      color="cloud.700"
      {...props}
    >
      <Box textAlign="center">
        <Text fontWeight="bold" fontSize={{ base: 'xl', md: '2xl' }}>
          {alert}
        </Text>
        <Text
          marginTop={2}
          fontWeight="bold"
          fontSize={{ base: 'xl', md: '2xl' }}
        >
          {suggestion}
        </Text>
      </Box>
    </Center>
  )
}
