import { FC, ReactElement } from 'react'
import { Box, BoxProps, Text } from '@chakra-ui/react'

import ChevronLeft from 'ui/components/Icons/ChevronLeft'

export interface CbColorBannerProps extends BoxProps {
  backArrowText?: string
  colorScheme?: string
  hasBackArrow?: boolean
  hasColorEdit?: boolean
  onBackArrowClick?: () => void
  onColorChange?: (color: string) => void
  title?: string
  rightElement?: ReactElement | null
  componentColor?: string
}

export const CbColorBanner: FC<CbColorBannerProps> = ({
  backArrowText,
  colorScheme,
  hasBackArrow = false,
  onBackArrowClick,
  title,
  rightElement,
  componentColor = 'white',
  ...props
}) => {
  return (
    <Box
      backgroundColor={colorScheme}
      borderBottomRadius={{ base: 24 }}
      display="flex"
      justifyContent="space-between"
      position="relative"
      paddingX={{ base: 4, lg: 6 }}
      paddingTop={{ base: 10, md: '80px' }}
      paddingBottom={{ base: 4, md: 6 }}
      {...props}
    >
      <Box alignItems="center" display="flex" minHeight={10}>
        {hasBackArrow && (
          <Box
            display="flex"
            alignItems="center"
            top={{ base: -8, md: -14 }}
            left="0"
            as="button"
            aria-label="Go Back"
            height={10}
            width={10}
            onClick={onBackArrowClick}
            opacity="0.6"
            textAlign="left"
            _hover={{
              opacity: '1',
            }}
          >
            <ChevronLeft
              color={componentColor}
              height={backArrowText ? '11px' : '16px'}
              width={backArrowText ? '11px' : '16px'}
            />
            {backArrowText && (
              <Text
                color={componentColor}
                fontSize={{ base: 'sm', md: 'md' }}
                whiteSpace="nowrap"
                textDecoration="underline"
                marginLeft="6px"
              >
                {backArrowText}
              </Text>
            )}
          </Box>
        )}
        <Text
          as="h1"
          fontSize={{ base: '2xl', md: '4xl' }}
          fontFamily="codenext"
          color={componentColor}
          fontWeight="bold"
          whiteSpace="nowrap"
        >
          {title}
        </Text>
      </Box>
      {rightElement}
    </Box>
  )
}
