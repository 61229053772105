import { FC, SyntheticEvent } from 'react'
import {
  Box,
  BoxProps,
  Divider,
  Flex,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { CbGameWinner } from '../CbGameWinner'
import { GameCard, Reward, gameDisplay, GameType } from '../../types/Games'
import { ICON_BY_NAME } from '../Icons/Icon'
import Coins from '../../assets/Coins'
import { CbTimeFromNow } from '../CbTimeFromNow'
import { formatNumber, getOrdinal } from '../../helpers'
import { CbButton } from '../Buttons/CbButton'
import RaffleTicket from 'ui/assets/RaffleTicket'
import {
  Position,
  Score,
  Winner,
  PlayerCount,
  RewardCount,
} from './GameDetails'

export interface CbGameCardProps extends Partial<Omit<BoxProps, 'id'>> {
  coverImageUrl?: string | null
  endDateTime?: Date
  gameType: GameCard['gameType']
  goal: number
  hasGameButton?: boolean
  hasGameClick?: boolean
  hasPlace?: boolean
  id: number
  isCompleted?: boolean
  isCurrentUser?: boolean
  isUpcoming?: boolean
  managerView?: boolean
  metric: string
  name: string
  numberOfWinners?: number
  onGameClick?: (id: number) => void
  onWinningsClick?: (event: SyntheticEvent) => void
  place?: number
  playerCount?: number
  prizeAmount?: number
  prizeType: Reward['type']
  rewards: Reward[]
  rewardsAwardedCount?: number
  startDateTime?: Date
  topWinner?: string
  totalScore: number
  unit: string
  userImageUrl?: string
  userName?: string
}

export const CbGameCardOld: FC<CbGameCardProps> = ({
  coverImageUrl,
  endDateTime,
  gameType,
  goal,
  hasGameButton = true,
  hasGameClick = true,
  hasPlace = true,
  id,
  isCompleted,
  isCurrentUser,
  isUpcoming,
  managerView,
  metric,
  name,
  numberOfWinners,
  onGameClick,
  onWinningsClick,
  place,
  playerCount,
  prizeAmount,
  prizeType,
  rewards,
  rewardsAwardedCount,
  startDateTime,
  totalScore,
  unit,
  userImageUrl,
  userName = '',
  ...props
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const handleGameClick = (event: SyntheticEvent) => {
    event.stopPropagation()
    hasGameClick && onGameClick && onGameClick(id)
  }

  const handleWinningsClick = (event: SyntheticEvent) => {
    event.stopPropagation()
    onWinningsClick && onWinningsClick(event)
  }

  const renderWinConditionText = () => {
    switch (gameType) {
      case GameType.Tournament:
        return `Top ${numberOfWinners}`
      case GameType.Race:
        return `First ${numberOfWinners}`
      default:
        return `Unlimited`
    }
  }

  const hasWinners =
    gameType === GameType.Race || gameType === GameType.Tournament

  const renderGameGoal = () => {
    if (gameType === GameType.Tournament) {
      return (
        <>
          <Text
            color="white"
            flexWrap={'wrap'}
            fontFamily={'codenext'}
            fontSize={{ base: 'sm', md: 'md' }}
            maxWidth={'100%'}
            textAlign={'center'}
          >{`${metric}`}</Text>
        </>
      )
    }

    return (
      <>
        <Text
          color="white"
          flexWrap={'wrap'}
          fontFamily={'codenext'}
          fontSize="xl"
          isTruncated
        >{`${formatNumber(goal)}`}</Text>
        <Text
          color="white"
          flexWrap={'wrap'}
          fontFamily={'codenext'}
          fontSize={'sm'}
          maxWidth={'100%'}
          textAlign={'center'}
        >{`${metric}`}</Text>
      </>
    )
  }

  return (
    <Box
      _hover={hasGameClick ? { transform: 'scale(1.0125)' } : undefined}
      borderRadius={24}
      boxShadow="0px 4px 21px 3px rgba(0, 0, 0, 0.09)"
      cursor={hasGameClick ? 'pointer' : undefined}
      display={isMobile ? 'block' : 'flex'}
      onClick={handleGameClick}
      overflow="hidden"
      transformOrigin="center"
      transition="transform 0.35s ease"
      {...props}
    >
      <Box
        background={
          isCompleted
            ? gameDisplay[gameType].completedBgColor
            : gameDisplay[gameType].bgColor
        }
        position="relative"
        width={isMobile ? '100%' : '40%'}
      >
        <Box height={isMobile ? '310px' : '100%'}>
          {isCompleted ? (
            <Flex
              alignItems="center"
              height="100%"
              justifyContent="center"
              width="100%"
            >
              {userName ? (
                <CbGameWinner
                  amount={prizeAmount}
                  imageUrl={userImageUrl}
                  isCurrentUser={isCurrentUser}
                  name={userName}
                  onClick={handleWinningsClick}
                  prizeType={prizeType}
                />
              ) : (
                <Text color="text.primaryWhite" fontFamily="codenext">
                  {'No Winner'}
                </Text>
              )}
            </Flex>
          ) : (
            <Box
              backgroundImage={`url("${
                coverImageUrl || gameDisplay[gameType].image
              }")`}
              backgroundPosition="center"
              backgroundSize="cover"
              height="100%"
              width="100%"
              {...props}
            />
          )}
        </Box>
      </Box>
      <Box
        backgroundColor="luigisPants.600"
        border="1px solid"
        borderBottomLeftRadius={isMobile ? 24 : 0}
        borderBottomRightRadius={24}
        borderColor="luigisPants.600"
        borderLeft={isMobile ? '1px solid' : 'none'}
        borderTop={isMobile ? 'none' : '1px solid'}
        borderTopRightRadius={isMobile ? 0 : 24}
        display={isMobile ? 'block' : 'flex'}
        flexDirection="column"
        flexGrow={1}
        justifyContent="space-between"
        minHeight={isMobile ? 'auto' : '360px'}
        paddingX={isMobile ? 5 : 12}
        paddingY={5}
        width={isMobile ? '100%' : '47%'}
      >
        <Box>
          <Text
            color="chonky.400"
            fontWeight="bold"
            letterSpacing="wider"
            marginBottom={isMobile ? 2 : 0}
            textTransform="uppercase"
          >
            {isUpcoming ? 'Upcoming ' : ''}
            {gameDisplay[gameType].title}
          </Text>

          <Text
            color="white"
            fontFamily="codenext"
            fontSize={isMobile ? 'xl' : '2xl'}
            noOfLines={2}
          >
            {name}
          </Text>
        </Box>
        <Box>
          <Flex
            marginTop={isMobile ? 5 : 10}
            flexDirection={{ base: 'column', md: 'row' }}
          >
            {managerView ? (
              <>
                <PlayerCount playerCount={playerCount} />
                <RewardCount rewardsAwardedCount={rewardsAwardedCount} />
                {!isCompleted && <Winner userName={userName} />}
              </>
            ) : (
              <>
                {hasPlace && <Position place={place} />}
                <Score metric={metric} totalScore={totalScore} />
                {!isCompleted && <Winner userName={userName} />}
              </>
            )}
          </Flex>
          <Text
            color="cloud.500"
            fontSize="xs"
            fontWeight="bold"
            marginBottom={4}
            marginTop={6}
            textAlign={'center'}
          >
            {'HOW TO PLAY'}
          </Text>
          <Divider marginBottom={8} />
          <Flex paddingX={8} justifyContent="space-around">
            {hasWinners && (
              <Flex direction="column" alignItems={'center'} maxWidth="33%">
                <Text fontFamily={'codenext'} fontSize={'xs'} color="cloud.500">
                  {'Winners'}
                </Text>
                <Text
                  color="white"
                  fontFamily={'codenext'}
                  fontSize={{ md: 'lg' }}
                  isTruncated
                  maxWidth="100%"
                >
                  {renderWinConditionText()}
                </Text>
              </Flex>
            )}
            <Flex direction="column" alignItems={'center'} maxWidth="33%">
              <Text
                color="cloud.500"
                fontFamily={'codenext'}
                fontSize={'xs'}
                textAlign={'center'}
              >
                {'Player Target'}
              </Text>
              {renderGameGoal()}
            </Flex>
            {!isCompleted && (
              <Flex direction="column" alignItems={'center'} maxWidth="33%">
                <Text
                  color="cloud.500"
                  fontFamily={'codenext'}
                  fontSize={'xs'}
                  textAlign={'center'}
                >
                  {'Time Left'}
                </Text>
                <CbTimeFromNow
                  color="white"
                  endDateTime={endDateTime}
                  fontFamily={'codenext'}
                  fontSize={{ md: 'lg' }}
                  isTruncated
                  isUpcoming={isUpcoming}
                  maxWidth="100%"
                  startDateTime={startDateTime}
                />
              </Flex>
            )}
          </Flex>
          <Flex
            alignItems={'center'}
            backgroundColor={'#12275A'}
            borderRadius={16}
            direction="column"
            marginTop={8}
            padding={1}
          >
            <Text
              color="cloud.500"
              fontSize="xs"
              fontWeight="bold"
              marginTop={2}
              textAlign={'center'}
            >
              {'PRIZES'}
            </Text>
            <Flex
              width="100%"
              justifyContent={
                rewards.length > 3 ? 'space-between' : 'space-evenly'
              }
              marginY={4}
              flexWrap={'wrap'}
              paddingX={2}
              flexDirection={{ base: 'column', md: 'row' }}
            >
              {rewards.map((reward, i) =>
                reward.type === 'token_jackpot' ? (
                  <Flex alignItems={'center'} key={i}>
                    <Flex
                      alignItems={'center'}
                      backgroundColor="luigisPants.600"
                      borderRadius={100}
                      color="white"
                      fontFamily={'codenext'}
                      fontSize="sm"
                      height={'35px'}
                      justifyContent={'center'}
                      marginRight={2}
                      padding={2}
                      width={'35px'}
                    >
                      {getOrdinal(i + 1)}
                    </Flex>
                    <RaffleTicket width={9} height="auto" marginRight={2} />
                    <Text color="white" fontWeight={'bold'}>
                      {`x ${formatNumber(
                        Number(reward.value.common || reward.value),
                      )}`}
                    </Text>
                  </Flex>
                ) : (
                  <Flex alignItems={'center'} marginBottom={2} key={i}>
                    <Flex
                      alignItems={'center'}
                      backgroundColor="luigisPants.600"
                      borderRadius={100}
                      color="white"
                      fontFamily={'codenext'}
                      fontSize="sm"
                      height={'35px'}
                      justifyContent={'center'}
                      marginRight={4}
                      padding={2}
                      width={'35px'}
                    >
                      {getOrdinal(i + 1)}
                    </Flex>
                    <Coins width={9} height="auto" marginRight={2} />
                    <Text color="white" fontWeight={'bold'}>
                      {formatNumber(Number(reward.value))}
                    </Text>
                  </Flex>
                ),
              )}
            </Flex>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="flex-end"
            marginTop={isMobile ? 6 : 8}
          >
            {hasGameButton && (
              <CbButton variant="ghost-on-dark" onClick={handleGameClick}>
                {'See Game'}
                <ICON_BY_NAME.arrowright height="14px" marginLeft={2} />
              </CbButton>
            )}
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}
