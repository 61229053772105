import { FC, SyntheticEvent } from 'react'
import { Box, Flex, FlexProps, Image, Text } from '@chakra-ui/react'
import { CbButton } from 'ui'
import winnerBg from '../../assets/images/winner-bg.png'
import RaffleTicket from 'ui/assets/RaffleTicket'
import Coins from '../../assets/Coins'
import { CbAvatar } from 'ui'
import { formatNumber } from '../../helpers'
import { Reward, RewardType } from '../../types/Games'
import { CbRaffleTicketBalance } from '../CbRaffleTicketBalance'

export interface CbGameWinnerProps extends FlexProps {
  amount?: number
  fontSize?: string
  imageUrl?: string
  isCurrentUser?: boolean
  name: string
  prizeType: Reward['type']
  onClick?: (event: SyntheticEvent) => void
}

export const CbGameWinner: FC<CbGameWinnerProps> = ({
  amount,
  fontSize,
  imageUrl,
  isCurrentUser,
  name,
  prizeType,
  onClick,
  ...props
}) => {
  const getPrizeType = (type: Reward['type']) => {
    switch (type) {
      case RewardType.Chests:
        return (
          <Box data-testid="chests">
            <CbRaffleTicketBalance
              color="text.primaryWhite"
              raffleTicketBalance={amount}
              fontWeight="bold"
              isPending={false}
            />
          </Box>
        )
      case RewardType.Tokens:
        return (
          <Flex data-testid="tokens" alignItems="center">
            <Coins width={9} height="auto" marginRight={2} />
            {amount && (
              <Text color="text.primaryWhite" fontSize="sm" fontWeight="bold">
                {formatNumber(amount)}
              </Text>
            )}
          </Flex>
        )
      case RewardType.Loot:
        return (
          <Flex data-testid="loot-boxes" alignItems="center">
            <RaffleTicket width={9} height="auto" marginRight={2} />
          </Flex>
        )
      case RewardType.Jackpot:
        return (
          <Flex data-testid="jackpot" alignItems="center">
            <RaffleTicket width={9} height="auto" marginRight={2} />
            <Text color="white">x {amount && formatNumber(amount)}</Text>
          </Flex>
        )
      default:
        return (
          <Text color="text.primaryWhite" fontSize="sm" fontWeight="bold">
            {'Custom Reward'}
          </Text>
        )
    }
  }

  return (
    <Flex alignItems="center" flexDirection="column" {...props}>
      <Box position="relative" marginBottom={8}>
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-48%,-55%)"
          width="275%"
          zIndex="0"
        >
          <Image src={winnerBg} width="100%" height="auto" />
        </Box>
        <CbAvatar
          data-testid="user avatar"
          height="120px"
          image={imageUrl}
          name={name}
          width="120px"
        />
      </Box>
      {getPrizeType(prizeType)}
      {isCurrentUser ? (
        <>
          <Text
            color="text.primaryWhite"
            fontFamily="codenext"
            fontSize={fontSize}
            marginTop={1}
            marginBottom={2}
          >
            {'Congrats You Won!'}
          </Text>
          <CbButton variant="ghost-on-dark" onClick={onClick}>
            {'Spend Winnings'}
          </CbButton>
        </>
      ) : (
        <Box marginTop={4} textAlign="center">
          <Text
            color="text.primaryWhite"
            fontFamily="codenext"
            fontSize={fontSize}
          >
            {name}
          </Text>
          <Text color="text.primaryWhite" fontSize="lg">
            {'Winner'}
          </Text>
        </Box>
      )}
    </Flex>
  )
}
